import "swiper/swiper-bundle.css";
import "photoswipe/dist/photoswipe.css";
import "../sdist/tailwind.css";

import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from "swiper";

import { createApp } from 'vue';
import VueGoogleMaps from 'vue-google-maps-community-fork'

import Radar from './js/partner/Radar.vue';

import Alpine from "alpinejs";
import initPhotoSwipeFromDOM from './js/photoswipe.js';
import collapse from "@alpinejs/collapse";


Alpine.plugin(collapse);


/*** Gallery Swiper -> region detail ***/
var swiper = new Swiper(".gallery-thumbs", {
  modules: [Navigation],
  loop: true,
  spaceBetween: 10,
  slidesPerView: 2,
  direction: "vertical",
  freeMode: true,
  watchSlidesProgress: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
var swiper2 = new Swiper(".gallery-top", {
  modules: [Navigation, Thumbs],
  loop: true,
  spaceBetween: 10,
  thumbs: {
    swiper: swiper,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

/*** stage swiper -> partner ***/
// swiper 3 images
var swiper_stage = new Swiper(".swiper_stage", {
  modules: [Pagination, Autoplay],
  loop: true,
  autoplay: {
    delay: 10000,
  },
  autoHeight: false,
  slidesPerView: 1,
  //spaceBetween: 10,
  effect: 'fade',
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

var swiper_start_partner = new Swiper(".swiper_start_partner", {
  modules: [Pagination],
  loop: true,
  autoHeight: false,
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

window.addEventListener("load", function () {


  // swiper 3 images
  var swiper_3img = new Swiper(".swiper_3img", {
    modules: [Navigation, Pagination],
    loop: true,
    autoHeight: false,
    slidesPerView: 1.5,
    centeredSlides: true,
    spaceBetween: 10,
    breakpoints: {
      // md
      768: {
        slidesPerView: 2.25,
        spaceBetween: 20,
      },
      // xl
      1280: {
        slidesPerView: 3.25,
        spaceBetween: 30,
      },
    },
    freeMode: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  if (document.getElementsByClassName('gallery-main').length) {
    var galleryThumbs = {};

    if (document.getElementsByClassName('gallery-thumbs-1x').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs-1x', {
        modules: [Navigation],
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
            direction: 'vertical',
          },
        }
      })};
    } else if (document.getElementsByClassName('gallery-thumbs-3x').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs-3x', {
        modules: [Navigation],
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
            direction: 'vertical',
            centeredSlides: true,
          },
        }
      })};
    }

    var galleryTop = new Swiper('.gallery-main', {
      modules: [Navigation, Thumbs, Pagination],
      spaceBetween: 0,
      preloadImages: false,
      pagination: {
        el: '.gallery-main-indicator',
        type: 'fraction',
      },
      // Enable lazy loading
      lazy: true,
      thumbs: galleryThumbs,
    });

    initPhotoSwipeFromDOM('.gallery-main');

  }

  initPhotoSwipeFromDOM('.gallery-line');

});


const $partners = Array.prototype.slice.call(document.querySelectorAll('#ZbPartner'), 0);

if ($partners.length > 0) {

  const app = createApp({});

  app.component(
    'Radar', Radar
  )

  app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDeySUzkx6-T6dCkT3P84b03p7EP8j1QBU',
    },
  }).mount('#ZbPartner')


  // Get all "map" elements
  /* const $maps = Array.prototype.slice.call(document.querySelectorAll('#ZbMap'), 0);

  if ($maps.length > 0) {

    new Vue({
      el: '#ZbMap',
      components: {
        ZbMap
      }
    });
  } */


}



Alpine.start();
